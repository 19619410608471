import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import { Layout, SEO } from "../../../components/structure";
import { Content, Hero } from "../../../components/blocks";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import Logo from "../../../components/structure/Header/Logo";

const ProcessorPage = () => {
  const Headerlogo = useStaticQuery(graphql`
    query {
      plusgrade_logo: file(base: { eq: "Plusgrade-Primary-Dark.png" }) {
        childImageSharp {
          fixed(width: 277, height: 46, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const ProcessorList = styled.div`
    ${font.imports.sherika}
    padding-top: 180px;
    width: 95%;
    max-width: 1400px;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .logo-con {
      width: 277px;
      height: 46px;
    }

    h2 {
      color: ${brand.colors.pg_primary_dark};
      font-family: ${font.family.sherika};
      font-size: 56px;
      font-weight: 700;
      padding-top: 40px;
    }

    p {
      font-family: ${font.family.sherika};
      color: ${brand.colors.pg_primary_dark};
    }

    table {
      width: 100%;
      max-width: 1100px;
      min-width: 400px;
      overflow: scroll;
      margin: auto;

      @media only screen and ${mq.maxMd} {
        display: none;
      }

      thead {
        tr {
          td {
            background-color: ${brand.colors.pg_secondary_dark};
            color: #fff;
            text-align: left;
            padding: 10px;
          }
        }
      }

      tbody {
        tr {
          td {
            color: ${brand.colors.pg_primary_dark};
            text-align: left;
            padding: 10px;

            a {
              color: ${brand.colors.pg_blue};
              text-decoration: underline;
              curosr: pointer;
            }

            &:nth-of-type(3) {
              width: 155px;
            }
            &:nth-of-type(4) {
              width: 200px;
            }
          }
        }
      }
    }

    .mobile-table {
      display: none;

      .provider-con {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-bottom: 1px solid ${brand.colors.pg_secondary_dark};
        padding: 10px 0;

        span {
          color: ${brand.colors.pg_primary_dark};

          a {
            color: ${brand.colors.pg_blue};
            text-decoration: underline;
            curosr: pointer;
          }
        }
      }

      @media only screen and ${mq.maxMd} {
        display: block;
      }
    }
  `;

  return (
    <Layout>
      <SEO
        title="Hospitality Sub-processors | Plusgrade"
        description={"Plusgrade's Hospitality Sub-processors"}
      />
      <Content whiteBG noMargin>
        <ProcessorList>
          <div className="logo-con">
            <GatsbyImage
              fixed={Headerlogo["plusgrade_logo"].childImageSharp.fixed}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <h2>Plusgrade's Hospitality Sub-processors</h2>
          <p>Current as of February 6, 2025</p>

          <table>
            <thead>
              <tr>
                <td>Vendor</td>
                <td>Purpose</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Smartlook</td>
                <td>QA, Product Analysis</td>
              </tr>
              <tr>
                <td>Cloudinary</td>
                <td>Image Storage</td>
              </tr>
              <tr>
                <td>MailGun</td>
                <td>Email delivery</td>
              </tr>
              <tr>
                <td>Cypress</td>
                <td>End-to-end tests</td>
              </tr>
              <tr>
                <td>Sentry</td>
                <td>Error tracking</td>
              </tr>
              <tr>
                <td>Dead Man's Snitch</td>
                <td>Server monitoring</td>
              </tr>
              <tr>
                <td>Papertrail</td>
                <td>Logs management</td>
              </tr>
              <tr>
                <td>Snyk</td>
                <td>Security</td>
              </tr>
              <tr>
                <td>Heroku</td>
                <td>Production environment</td>
              </tr>
              <tr>
                <td>BigQuery</td>
                <td>DB</td>
              </tr>
              <tr>
                <td>AWS S3</td>
                <td>DB</td>
              </tr>
              <tr>
                <td>Auth0</td>
                <td>User login and admin</td>
              </tr>
              <tr>
                <td>Twillio</td>
                <td>SMS and Whatsapp delivery</td>
              </tr>
            </tbody>
          </table>

          <div className="mobile-table">
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Smartlook
              </span>
              <span>
                <strong>Purpose: </strong>QA, Product Analysis
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Cloudinary
              </span>
              <span>
                <strong>Purpose: </strong>Image Storage
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>MailGun
              </span>
              <span>
                <strong>Purpose: </strong>Email delivery
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Cypress
              </span>
              <span>
                <strong>Purpose: </strong>End-to-end tests
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Sentry
              </span>
              <span>
                <strong>Purpose: </strong>Error tracking
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Dead Man's Snitch
              </span>
              <span>
                <strong>Purpose: </strong>Server monitoring
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Papertrail
              </span>
              <span>
                <strong>Purpose: </strong>Logs management
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Snyk
              </span>
              <span>
                <strong>Purpose: </strong>Security
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Heroku
              </span>
              <span>
                <strong>Purpose: </strong>Production environment
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>BigQuery
              </span>
              <span>
                <strong>Purpose: </strong>DB
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>AWS S3
              </span>
              <span>
                <strong>Purpose: </strong>DB
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Auth0
              </span>
              <span>
                <strong>Purpose: </strong>User login and admin
              </span>
            </div>
            <div className="provider-con">
              <span>
                <strong>Vendor: </strong>Twillio
              </span>
              <span>
                <strong>Purpose: </strong>SMS and Whatsapp delivery
              </span>
            </div>
          </div>
        </ProcessorList>
      </Content>
    </Layout>
  );
};

export default ProcessorPage;
